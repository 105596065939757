import JSimg from '../img/js.svg'
import Vueimg from '../img/vue.svg'
import ReactImg from '../img/react.svg'
import htmlimg from '../img/html.svg'
import cssimg from '../img/css.svg'
import sassimg from '../img/sass.svg'
import gitimg from '../img/git.svg'
import gif from '../img/liam.gif'
import { PDFDownloadLink } from '@react-pdf/renderer'
import CurriculumPrint from './CurriculumPrint'

const Curriculum = () => (
  <>
    <div className='content_wrapper boxed'>
      <div className='section'>
        <h2>.in breve</h2>
        <p>
          Lavoro come sviluppatrice web e front-end da circa 7 anni,
          concentrandomi principalmente sulla realizzazione di siti, web app e
          piccoli e-commerce. Utilizzo diverse tecnologie, framework e CMS in
          base alle necessità e alle specifiche di ciascun progetto. Svolgo la
          mia attività come consulente e libera professionista.
        </p>
        <p>
          L'esperienza come freelance mi ha insegnato a gestire e organizzare il
          tempo, gli impegni e i costi, valutando di volta in volta l'approccio
          migliore per ogni progetto specifico. Il lavoro in agenzia mi consente
          di affrontare progetti più ampi e complessi, offrendomi l'opportunità
          di collaborare e confrontarmi con altri professionisti. Questa doppia
          esperienza mi permette di lavorare sia in completa autonomia sia come
          parte di un team, in ambienti diversificati e con una vasta gamma di
          persone. Una flessibilità che mi rende capace di adattarmi facilmente
          a diverse situazioni lavorative
        </p>
        <p>
          Qui una selezione di progetti a cui ho lavorato:
          <a
            className='underlined'
            target='_blank'
            rel='noreferrer'
            href='https://antonioli.dev/works'
          >
            antonioli.dev/works
          </a>
        </p>
      </div>
      <div className='section'>
        <h2>.esperienza professionale</h2>
        <div className='flex'>
          <div className='data'>2016 - ad oggi</div>
          <div className='content'>
            <h4>Sviluppatrice front-end e web - Freelance</h4>
            <ul>
              <li>
                Realizzazione di siti, web e desktop app con JS, SCSS, html,
                React e, a volte, Vue;
              </li>
              <li>
                sviluppo di temi WordPress, quasi sempre col supporto del plugin
                ACF, spesso con Twig (e Timber) oppure headless;
              </li>
              <li>
                creazione di piccoli e-commerce con WooCommerce o Shopify;
              </li>
              <li>supporto e manutenzione.</li>
            </ul>
            <p>
              Spesso collaboro con altri professionisti e con agenzie. Tra gli
              altri:{' '}
              <a
                className='underlined'
                href='https://piramid.studio/'
                target='_blank'
                rel='noreferrer'
              >
                Piramid (Torino)
              </a>
              ,{' '}
              <a
                className='underlined'
                href='https://iride.digital/'
                target='_blank'
                rel='noreferrer'
              >
                Iride Comunicazione (Parma)
              </a>
              ,{' '}
              <a
                className='underlined'
                href='https://www.purplenetwork.it/'
                target='_blank'
                rel='noreferrer'
              >
                Purple Network
              </a>{' '}
              e{' '}
              <a
                className='underlined'
                href='https://www.pixelcrew.it/'
                target='_blank'
                rel='noreferrer'
              >
                Pixel Crew
              </a>
            </p>
          </div>
        </div>
        <div className='flex'>
          <div className='data'>
            Feb - Ott 2024
            <br />
            <b>BasicNet S.p.A.</b>
          </div>
          <div className='content'>
            <h4>Sviluppatrice front-end e Shopify - Consulente</h4>
            <ul>
              <li>Creazione e ottimizzazione di nuove features in React;</li>
              <li>Gestione del tema per i negozi;</li>
              <li>
                Sviluppo di applicazioni custom per Shopify, con Remix, REST
                Api, GraphQl;
              </li>
              <li>Migrazione storico ordini da altri CMS, con MySql.</li>
            </ul>
          </div>
        </div>
        <div className='flex'>
          <div className='data'>
            2019 - 2023
            <br />
            <b>Conversa</b>
          </div>
          <div className='content'>
            <h4>Sviluppatrice front-end - Collaboratrice</h4>
            <ul>
              <li>Sviluppo front-end di siti e web app;</li>
              <li>realizzazione di componenti riutilizzabili;</li>
              <li>manutenzione, miglioramento e modifiche dei vecchi siti;</li>
              <li>analisi di fattibilità dei progetti;</li>
              <li>
                valutazione delle tecnologie da utilizzare per nuovi progetti.
              </li>
            </ul>
          </div>
        </div>
        <div className='flex'>
          <div className='data'>
            2019 - 2019
            <br />
            <b>Accenture</b>
          </div>
          <div className='content'>
            <h4>Sviluppatrice front-end - Consulente</h4>
            <ul>
              <li>
                restyling di alcuni dei siti di Stella Antis, all'epoca FCA.
              </li>
            </ul>
          </div>
        </div>
        <div className='flex'>
          <div className='data'>
            2017 - 2021
            <br />
            <b>OTTOSUNOVE</b>
          </div>
          <div className='content'>
            <h4>Consulente Web e sviluppatrice Web</h4>
            <ul>
              <li>Gestione e mantenimento dei vecchi siti;</li>
              <li>sviluppo di siti in WordPress.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='section'>
        <h2>.formazione</h2>

        <div className='flex'>
          <div className='data'>2011 - 2013</div>
          <div className='content'>
            <h4>Laurea Specialistica in Comunicazione e Culture dei Media</h4>
            <p className='smaller'>Università degli Studi di Torino</p>
          </div>
        </div>
        <div className='flex'>
          <div className='data'>2012 - 2013</div>
          <div className='content'>
            <h4>LLP Erasmus - Comunicación audiovisual</h4>
            <p className='smaller'>Universidad de Granada (Spain)</p>
          </div>
        </div>
        <div className='flex'>
          <div className='data'>2008 - 2011</div>
          <div className='content'>
            <h4>Laure in Scienze della Comunicazione</h4>
            <p className='smaller'>Università degli Studi di Torino</p>
          </div>
        </div>
        <div className='flex'>
          <div className='data'>2003 - 2008</div>
          <div className='content'>
            <h4>Diploma di maturità scientifica</h4>
            <p className='smaller'>
              Liceo Scientifico Statale "G.Galilei" - Ciriè(TO)
            </p>
          </div>
        </div>
      </div>

      <div className='section'>
        <h2>.competenze</h2>
        <h4>Utilizzo frequentemente</h4>
        <div className='toolbox'>
          <div>
            <img src={JSimg} alt='js icon' />
            Javascript
          </div>
          <div>
            <img src={ReactImg} alt='react icon' />
            React
          </div>
          <div>
            <img src={htmlimg} alt='html icon' />
            HTML
          </div>
          <div>
            <img src={cssimg} alt='css icon' />
            CSS
          </div>
          <div>
            <img src={sassimg} alt='sass icon' />
            Sass/Scss
          </div>
          <div>
            <img src={Vueimg} alt='vue icon' />
            Vue
          </div>
          <div>
            <img src={gitimg} alt='git icon' />
            GIT
          </div>
        </div>
        <div className='toolbox smaller'>
          <h4>Mi aiuto con</h4>
          <ul>
            <li>Tailwind, UIKit, Bulma </li>
            <li>React MUI</li>
            <li>Figma, XD, Sketch, InVision</li>
          </ul>
          {/* <div><img src={sketch} alt="sketch icon"/>Sketch</div>
                        <div><img src={invision} alt="invision icon"/>InVision</div>
                        <div><img src={photoshop} alt="photoshop icon"/>Photoshop</div>
                        <div><img src={illustrator} alt="illustrator icon"/>Illustrator</div>
                        <div><img src={xd} alt="xd icon"/>Adobe XD</div> */}
        </div>
        <div className='toolbox smaller'>
          <h4>Utilizzo anche</h4>
          <ul>
            <li>PHP </li>
            <li>Twig</li>
            <li>Wordpress, Shopify, GitHub Pages, Jekyll, Astro</li>
          </ul>
          {/* <div><img src={sketch} alt="sketch icon"/>Sketch</div>
                        <div><img src={invision} alt="invision icon"/>InVision</div>
                        <div><img src={photoshop} alt="photoshop icon"/>Photoshop</div>
                        <div><img src={illustrator} alt="illustrator icon"/>Illustrator</div>
                        <div><img src={xd} alt="xd icon"/>Adobe XD</div> */}
        </div>
      </div>

      <div className='section'>
        <h2>.lingue</h2>
        <div>
          <b>Italiano</b> - madrelingua
        </div>
        <div>
          <b>Inglese</b> - B2
        </div>
        <div>
          <b>Spagnolo</b> - B2
        </div>
      </div>
      <div className='section'>
        <h2>.corsi di formazione</h2>
        <div>
          2019 - <b>Graphic Design</b> - Forte Chance Piemonte
        </div>
        <div>
          2016 - <b>Web Development</b> - Delpho Didattica Informatica
        </div>
        <div>
          2014 -2016 - <b>Master di I livello in Tourism Management</b> -
          Universidad Politécnica de Cartagena(Murcia - ES)
        </div>
      </div>
      <p className='copy'>
        Autorizzo il trattamento dei miei dati personali, ai sensi del D.lgs.
        196 del 30 giugno 2003
      </p>

      <div className='button-download'>
        <PDFDownloadLink
          document={<CurriculumPrint />}
          fileName='annalisa_antonioli_cv.pdf'
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              'Loading ...'
            ) : (
              <img
                src={gif}
                width={160}
                title='Versione stampabile'
                alt='Liam print'
              />
            )
          }
        </PDFDownloadLink>
        <span>Versione stampabile</span>
      </div>
    </div>
  </>
)
export default Curriculum
